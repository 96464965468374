<template>      
	<form @submit.prevent="checkForm" class="mb-4">
		<div class="row">
			<div class="col-12" v-if="form_message !== ''">
				<ErrorAlert :messageI18n="form_message" />
			</div>

			<div class="col-12">
				<div class="form-group">
					<label for="tiers_id">{{ $t('tiers.billing_entity') }} *</label>
					<e-select
						v-model="default_form.author"
						id="entity_id"
						track-by="id"
						label="rs"
						:placeholder="labelTitleEntity"
						:selectedLabel="selectedLabel"
						:options="entity"
						:searchable="true"
						:allow-empty="false"
						:loading="isLoadingEntity"
						:show-labels="false"
					>
						<template slot="singleLabel" slot-scope="{ option }">{{ option.rs }}</template>
						<template slot="noOptions">{{ $t('global.list_empty') }}</template>
					</e-select>
				</div>
			</div>

			<template v-if="default_form.author ">
				<div class="col-12" v-if="!isAddArticle">
					<div class="row align-items-end">
						<div class="col-7">
							<div class="form-group">
								<label for="search_article">{{ $t('invoice.article_a_facturer') }}</label>
								<e-select
									v-model="default_form.articles"
									id="search_article"
									track-by="name"
									label="name"
									:placeholder="labelTitle"
									:selectedLabel="selectedLabel"
									:options="options_articles"
									:searchable="true"
									:allow-empty="true"
									:loading="isLoading"
									:show-labels="false"
									:class="{ 'is-invalid': errors && errors.indexOf('Erreur no article selected') > -1 }"
								>
									<template slot="singleLabel" slot-scope="{ option }">{{ option.name }}</template>
									<template slot="noOptions">{{ $t('global.list_empty') }}</template>
								</e-select>
							</div>
						</div>

						<div class="col-5">
							<button class="btn btn-secondary btn-block mb-3" @click.prevent="addArticle"><font-awesome-icon :icon="['far', 'layer-plus']" /> {{ $t('invoice.creer_article') }}</button>
						</div>
					</div>

					<div class="row">
						<div class="col-12">
							<div class="form-group">
								<label for="articles_ht">{{ $t('compta.form.tarif_ht') }} *</label>
								<input type="text" class="form-control" v-model="default_form.htunit" required :class="{ 'is-invalid': errors && errors.indexOf('article_ht') > -1 }">
							</div>
						</div>
					</div>
				</div>

				<div class="col-12" v-if="isAddArticle">
					<div class="row">
						<div class="col-6">
							<div class="form-group">
								<label for="articles_label">{{ $t('compta.form.label_article') }} *</label>
								<input type="text" class="form-control" v-model="articles.articles_label" required :class="{ 'is-invalid': errors && errors.indexOf('add_label') > -1 }">
							</div>
						</div>
						<div class="col-6">
							<div class="form-group">
								<label for="articles_code">{{ $t('compta.form.code_article') }} *</label>
								<input type="text" class="form-control" v-model="articles.articles_code" required :class="{ 'is-invalid': errors && errors.indexOf('add_code') > -1 }">
							</div>
						</div>
					</div>

					<div class="row">
						<div class="col-6">
							<div class="form-group">
								<label for="articles_ht">{{ $t('compta.form.tarif_ht') }} *</label>
								<input type="text" class="form-control" v-model="articles.articles_ht" required :class="{ 'is-invalid': errors && errors.indexOf('add_ht') > -1 }">
							</div>
						</div>
						<div class="col-6">
							<div class="form-group">
								<label for="articles_vat">{{ $t('compta.form.vat') }} *</label>

								<e-select
									v-model="articles.articles_vat"
									id="search_vat"
									track-by="value"
									label="name"
									:placeholder="labelTitleVat"
									:selectedLabel="selectedLabel"
									:options="vat"
									:searchable="true"
									:allow-empty="false"
									:loading="isLoadingVat"
									:show-labels="false"
									:class="{ 'is-invalid': errors && errors.indexOf('add_vat') > -1 }"
								>
									<template slot="singleLabel" slot-scope="{ option }">{{ option.name }}</template>
									<template slot="noOptions">{{ $t('global.list_empty') }}</template>
								</e-select>
							</div>
						</div>
					</div>

					<div class="row">
						<div class="col-12">
						    <div class="form-group">
						        <label for="articles_articlestype" class="col-form-label">{{ $t("compta.form.articles_type") }} *</label>
						         <e-select
						            v-model="articles.articles_articlestype"
						            id="articles_articlestype"
						            track-by="articlestype_id"
						            label="articlestype_label"
						            :placeholder="labelTitleArticlesType"
						            :deselectLabel="deselectLabel"
						            :selectedLabel="selectedLabel"
						            :selectLabel="enter_to_select"
						            :options="articles_type"
						            :searchable="true"
						            :show-labels="false"
						            :allow-empty="false"
						            :class="{ 'is-invalid': errors && errors.indexOf('Erreur articles type') > -1 }"
						        />
						    </div>
						</div>

						<div class="col-12 col-sm-6">
							<div class="form-group">
								<label for="articles_accountingaccount" class="col-form-label">{{ $t("compta.form.compte") }} *</label>
								<e-select
									v-model="articles.articles_accountingaccount"
									id="search_compte"
									track-by="id"
									label="name"
									:placeholder="labelTitleCompteComptable"
									:selectedLabel="selectedLabel"
									:options="comptes_comptables"
									:searchable="true"
									:allow-empty="false"
									:loading="isLoadingCompteComptable"
									:show-labels="false"
									:class="{ 'is-invalid': errors && errors.indexOf('add_account') > -1 }"
								>
									<template slot="singleLabel" slot-scope="{ option }">{{ option.name }}</template>
									<template slot="noOptions">{{ $t('global.list_empty') }}</template>
								</e-select>
							</div>
						</div>

						<div class="col-12 col-sm-6">
						    <div class="form-group">
						        <label for="articles_vataccount" class="col-form-label">{{ $t("compta.form.vat_compte_comptable") }} *</label>
						        <e-select
						            v-model="articles.articles_vataccount"
						            id="articles_vataccount"
						            track-by="id"
						            label="name"
						            :placeholder="labelTitleCompteComptable"
						            :deselectLabel="deselectLabel"
						            :selectedLabel="selectedLabel"
						            :selectLabel="enter_to_select"
						            :options="comptes_comptables_vat"
						            :searchable="true"
						            :show-labels="false"
						            :allow-empty="false"
						            :class="{ 'is-invalid': errors && errors.indexOf('Erreur vat compte comptable') > -1 }"
						        >
						            <template slot="singleLabel" slot-scope="{ option }">{{ option.name }}</template>
						            <template slot="noOptions">{{ $t('global.list_empty') }}</template>
						        </e-select>
						        
						    </div>
						</div>

						<div class="col-12 col-sm-6">
						    <div class="form-group">
						        <label for="articles_accountingaccountfdvxp" class="col-form-label">{{ $t("compta.form.articles_accountingaccountfdvxp") }} *</label>
						        <e-select
						            v-model="articles.articles_accountingaccountfdvxp"
						            id="articles_accountingaccountfdvxp"
						            track-by="id"
						            label="name"
						            :placeholder="labelTitleCompteComptable"
						            :deselectLabel="deselectLabel"
						            :selectedLabel="selectedLabel"
						            :selectLabel="enter_to_select"
						            :options="comptes_comptables"
						            :searchable="true"
						            :show-labels="false"
						            :allow-empty="false"
						            :class="{ 'is-invalid': errors && errors.indexOf('Erreur compte comptable fdvxp') > -1 }"
						        >
						            <template slot="singleLabel" slot-scope="{ option }">{{ option.name }}</template>
						            <template slot="noOptions">{{ $t('global.list_empty') }}</template>
						        </e-select>
						    </div>
						</div>

						<div class="col-12 col-sm-6">
						    <div class="form-group">
						        <label for="articles_vataccountfdvxp" class="col-form-label">{{ $t("compta.form.articles_vataccountfdvxp") }} *</label>
						        <e-select
						            v-model="articles.articles_vataccountfdvxp"
						            id="articles_vataccountfdvxp"
						            track-by="id"
						            label="name"
						            :placeholder="labelTitleCompteComptable"
						            :deselectLabel="deselectLabel"
						            :selectedLabel="selectedLabel"
						            :selectLabel="enter_to_select"
						            :options="comptes_comptables_vat"
						            :searchable="true"
						            :show-labels="false"
						            :allow-empty="false"
						            :class="{ 'is-invalid': errors && errors.indexOf('Erreur vat compte comptable fdvxp') > -1 }"
						        >
						            <template slot="singleLabel" slot-scope="{ option }">{{ option.name }}</template>
						            <template slot="noOptions">{{ $t('global.list_empty') }}</template>
						        </e-select>
						    </div>
						</div>


						<div class="col-12 col-sm-6">
						    <div class="form-group">
						        <label for="articles_accountingaccountfdvex" class="col-form-label">{{ $t("compta.form.articles_accountingaccountfdvex") }} *</label>
						        <e-select
						            v-model="articles.articles_accountingaccountfdvex"
						            id="articles_accountingaccountfdvex"
						            track-by="id"
						            label="name"
						            :placeholder="labelTitleCompteComptable"
						            :deselectLabel="deselectLabel"
						            :selectedLabel="selectedLabel"
						            :selectLabel="enter_to_select"
						            :options="comptes_comptables"
						            :searchable="true"
						            :show-labels="false"
						            :allow-empty="false"
						            :class="{ 'is-invalid': errors && errors.indexOf('Erreur compte comptable fdvex') > -1 }"
						        >
						            <template slot="singleLabel" slot-scope="{ option }">{{ option.name }}</template>
						            <template slot="noOptions">{{ $t('global.list_empty') }}</template>
						        </e-select>
						    </div>
						</div>


						<div class="col-12 col-sm-6">
						    <div class="form-group">
						        <label for="articles_vataccountfdvex" class="col-form-label">{{ $t("compta.form.articles_vataccountfdvex") }} *</label>
						        <e-select
						            v-model="articles.articles_vataccountfdvex"
						            id="articles_vataccountfdvex"
						            track-by="id"
						            label="name"
						            :placeholder="labelTitleCompteComptable"
						            :deselectLabel="deselectLabel"
						            :selectedLabel="selectedLabel"
						            :selectLabel="enter_to_select"
						            :options="comptes_comptables_vat"
						            :searchable="true"
						            :show-labels="false"
						            :allow-empty="false"
						            :class="{ 'is-invalid': errors && errors.indexOf('Erreur vat compte comptable fdvex') > -1 }"
						        >
						            <template slot="singleLabel" slot-scope="{ option }">{{ option.name }}</template>
						            <template slot="noOptions">{{ $t('global.list_empty') }}</template>
						        </e-select>
						    </div>
						</div>

					</div>
				</div>

				<div class="col-12">
					<div class="form-group">
						<label for="">{{ $t('monte.choisir_condition') }} *</label>
						<e-select
							v-model="default_form.conditions"
							id="search_conditions"
							track-by="id"
							label="label"
							:placeholder="labelTitleConditionMonte"
							:selectedLabel="selectedLabel"
							:options="conditionsFormatted"
							:searchable="true"
							:allow-empty="false"
							:loading="isConditionsLoading"
							:show-labels="false"
							:class="{ 'is-invalid': errors && errors.indexOf('conditions') > -1 }"
						>
						</e-select>
					</div>
				</div>

				<div class="col-12">
					<div class="form-group">
						<label for="commentaire">{{ $t('invoice.commentaire') }}</label>
						<textarea id="commentaire" class="form-control" rows="3" v-model="default_form.comment"></textarea>
					</div>
				</div>
			</template>
		</div>
	</form>
</template>

<script type="text/javascript">
	import Vue from 'vue'
	import { EventBus } from 'EventBus'


	import TableAction from "@/mixins/TableAction.js"
	import Accounting from "@/mixins/Accounting.js"
	import Navigation from "@/mixins/Navigation.js"
	import Config from "@/mixins/Config.js"
    import Article from "@/mixins/Article.js"
    import Invoice from "@/mixins/Invoice.js"
    import Tiers from "@/mixins/Tiers.js"
    import Contract from "@/mixins/Contract.js"


	export default {
		name: "addArticle",
		mixins: [Config, TableAction, Accounting, Navigation, Article, Invoice, Tiers, Contract],
		props: {
			tiers_id: {},
			avenant_id: {},
			typemonte_id: {},
			contract_id: {},
			processing: {},
			ready: {},
			horse_id: {},
			created_horse_id: {},
		},
		data () {
			return {
				form_message: '',
				isAddArticle: false,

				default_form: {
					conditions: null,
					description: null,
					ht: null,
					htunit: null,
					qte: 1,
					vat: 1,
					articles: null,
					tiers: null,
					author: null,
					comment: null,
				},
				articles: {
					articles_label: null,
					articles_code: null,
					articles_ht: null,
					articles_vat: null,
					articles_accountingaccount: null,
					articles_accountingaccountfdvex: null,
					articles_accountingaccountfdvxp: null,
					articles_vataccount: null,
					articles_vataccountfdvex: null,
					articles_vataccountfdvxp: null,
				},

				entity_id: null,
				current_contract_id: null,
				articles_id: null,

				tiers: [],
				entity: [],
				conditions: [],
				errors: [],
				options_articles: [],

				isLoadingEntity: false,
				isLoadingTiers: false,
				isConditionsLoading: false,
				isLoadingVat: false,
				isLoadingCompteComptable: false,
				isLoading: false,

				cache: {
					horses: {},
					comptes_comptables: {},
					articles: {}
				},

				comptes_comptables: [],
				comptes_comptables_vat: [],
				vat: [],
				articles_type: [],

				labelTitleTiers: this.getTrad("tiers.rechercher"),
				labelTitleVat: this.getTrad("vat.rechercher"),
				labelTitleCompteComptable: this.getTrad("accounting_account.rechercher"),
				labelTitleEntity: this.getTrad("tiers.rechercher_billing_entity"),
				labelTitleConditionMonte: this.getTrad("monte.rechercher_condition"),
				labelTitle: this.getTrad("invoice.ligne_vierge_search_article"),
				labelTitleArticlesType: this.getTrad("compta.search_articles_type"),

				labelNotFound: this.getTrad("invoice.ligne_vierge_no_article_found"),
				labelSearchPlaceholder: this.getTrad("invoice.ligne_vierge_rechercher"),
				enter_to_select: this.getTrad("global.press_enter_to_select"),
				selectedLabel: this.getTrad("global.selected_label"),
				deselectLabel: this.getTrad("global.press_enter_to_remove"),
			}
		},
		mounted() {
			this.init_component()
		},
		created () {
		},
		methods: {
			async init_component() {
				this.loadAllTiers()
				this.loadConditions()
			},

			addArticle(){
				this.isAddArticle = true
			},

			async loadAllTiers(){
				this.isLoadingTiers = true
				this.isLoadingEntity = true
				this.$emit('update:ready', false)

				const entities = await this.loadTiersEntity()
				let nb_entity = entities.length;

                for (let i = 0; i < entities.length; i++) {
					//Si j'ai un id, je suis dans la modif je préselect
					if(this.entity_id == entities[i].tiers_id || nb_entity == 1){
						this.default_form.author = {
							id: entities[i].tiers_id,
							rs: entities[i].tiers_rs,
						}
					}

					this.entity.push({
						id: entities[i].tiers_id,
						rs: entities[i].tiers_rs,
					})
				}

				this.isLoadingTiers = false
				this.isLoadingEntity = false
			},

			async loadAllAfterTiers(){

				this.isLoading = true
				this.$emit('update:ready', false)
				this.options_articles = []
				this.vat = []

				const vat = await this.getVat()
				for (let i = 0; i < vat.length; i++) {
					this.vat.push({
						id: vat[i].vat_id,
						name: vat[i].vat_label,
						value: vat[i].vat_value,
					})
				}

				// Gestion cache compte comptable
				if(Object.keys(this.cache.comptes_comptables).length == 0) {
					this.cache.comptes_comptables = {}
				}

				let comptes_comptables = null
				if(this.cache.comptes_comptables[this.default_form.author.id] == null) {
					comptes_comptables = await this.loadAccountingAccountsTiers(this.default_form.author.id)
					this.cache.comptes_comptables[this.default_form.author.id] = this.comptes_comptables
				}
				else {
					comptes_comptables = this.cache.comptes_comptables[this.default_form.author.id]
				}

				this.comptes_comptables = []
				for (let i = 0; i < comptes_comptables.length; i++) {
					if(comptes_comptables[i].accountingaccount_vat == null) {
						this.comptes_comptables.push({
							id: comptes_comptables[i].accountingaccount_id,
							name: comptes_comptables[i].accountingaccount_number + " ( " + comptes_comptables[i].accountingaccount_label + " )",
						})
					}
					else
					{
						this.comptes_comptables_vat.push({
						    name: comptes_comptables[i].accountingaccount_number + " ("+ comptes_comptables[i].accountingaccount_label +")", 
						    id: comptes_comptables[i].accountingaccount_id })
					}
				}

				// Gestion cache article
				if(Object.keys(this.cache.articles).length == 0) {
					this.cache.articles = {}
				}
				this.articles_type = await this.getArticlesType()
				let result = null
				if(this.cache.articles[this.default_form.author.id] == null) {
					result = await this.loadArticlesByEntity(this.default_form.author.id);
					this.cache.articles[this.default_form.author.id] = result
				}
				else {
					result = this.cache.articles[this.default_form.author.id]
				}

				
				this.default_form.invoicedetails_articles = null
				for (let i = 0; i < result.length; i++) {
					this.options_articles.push({
						id: result[i].articles_id,
						name: result[i].articles_label,
						price: result[i].articles_ht,
						vat: result[i].articles_vat
					})
				}

				this.isLoading = false
				this.$emit('update:ready', true)
			},

			async loadConditions() {
				this.isConditionsLoading = true
				this.conditions = await this.getConditionsMonte()
				this.isConditionsLoading = false
			},

			async submitForm(){

				this.$emit('update:processing', false)
				if(!this.processing)
				{
					// this.processing = true
					this.$emit('update:processing', true)
					this.errors = []

					if(this.isAddArticle)
					{
						if(this.articles.articles_vat === null)
						{
							this.errors.push("add_vat")
						}

						if(this.articles.articles_accountingaccount === null)
						{
							this.errors.push("add_account")
						}

						if(!this.articles.articles_label)
						{
							this.errors.push("add_label")
						}

						if(!this.articles.articles_code)
						{
							this.errors.push("add_code")
						}

						if(!this.articles.articles_ht)
						{
							this.errors.push("add_ht")
						}

						if(this.articles.articles_accountingaccountfdvex === null)
						{
							this.errors.push("Erreur accounting account fdvex")
						}

						if(this.articles.articles_accountingaccountfdvxp === null)
						{
							this.errors.push("Erreur accounting account fdvxp")
						}

						if(this.articles.articles_vataccount === null)
						{
							this.errors.push("Erreur vat accounting account")
						}

						if(this.articles.articles_vataccountfdvex === null)
						{
							this.errors.push("Erreur vat accounting account fdvex")
						}

						if(this.articles.articles_vataccountfdvxp === null)
						{
							this.errors.push("Erreur vat accounting account fdvxp")
						}
					}
					else if(this.articles_id == null)
					{
						this.errors.push("Erreur no article selected")
					}

					if(this.default_form.conditions == null)
					{
						this.errors.push("conditions")
					}

					if(!this.isAddArticle && this.default_form.htunit == null || this.default_form.htunit == "")
					{
						this.errors.push("article_ht")
					}

					if(this.errors.length === 0)
					{
						this.form_message = ""
						let qte = this.default_form.qte
						let htunit = this.default_form.htunit
						let vat = this.default_form.vat
						let ttc = null
						let articles_id = null
						if(this.articles_id != null)
						{
							articles_id = this.articles_id.id
						}

					

						if(this.isAddArticle)
						{
							vat = this.vat.find(vat => { return vat.id == this.articles.articles_vat.id })
							const tva = parseFloat(vat.value)
							const ht = parseFloat(this.articles.articles_ht)
							ttc = (vat.value * ht) + ht

							let result = null
							result = await this.addArticles(
								this.articles.articles_accountingaccount.id,
								this.articles.articles_vat.id,
								this.articles.articles_label,
								this.articles.articles_code,
								this.articles.articles_ht,
								ttc,
								this.articles.articles_articlestype.articlestype_id,
								this.articles.articles_accountingaccountfdvex.id,
								this.articles.articles_accountingaccountfdvxp.id,
								this.articles.articles_vataccount.id,
								this.articles.articles_vataccountfdvex.id,
								this.articles.articles_vataccountfdvxp.id
							);

							await this.$sync.runOptionalWhishlist(['articles'])
							
							htunit = this.articles.articles_ht
							vat = this.articles.articles_vat.id
							
							if(result)
							{
								articles_id = result.retour.articles_id
							}
							else
							{
								this.form_message = "error.LEP"

								this.$emit('update:processing', false)
								return false;
							}
						}
						else
						{
							articles_id = this.default_form.articles.id
							vat = this.vat.find(vat => { return vat.id == this.default_form.articles.vat })
							const tva = parseFloat(vat.value)
							const ht = parseFloat(htunit)
							ttc = (vat.value * ht) + ht
						}

						let paramsToSend = {};
						paramsToSend['details'] = []

						let infos = {
							author_id : this.entity_id,
							tiers_id : this.tiers_id,
							ht : parseFloat(htunit),
							ttc : parseFloat(ttc),
							qte : qte,
							vat : vat,
							avenant_id : this.avenant_id,
							conditions : this.default_form.conditions.id,
							articles_id : articles_id,
							comment: this.default_form.comment,
							horse_id: this.horse_id ? this.horse_id : this.created_horse_id
						}

						if(this.contract_id < 0 && this.current_contract_id == null) {
							await this.$sync.force(true, true)
							this.current_contract_id = this.$sync.replaceWithReplicated('contract', this.contract_id)
							infos.avenant_id = this.$sync.replaceWithReplicated('contract_avenant', this.avenant_id)
						}
						else {
							this.current_contract_id = this.contract_id
						}

						let result_invoice_details = null

						result_invoice_details = await this.addAvenantArticleSolo(infos, this.current_contract_id)
						
						if(result_invoice_details)
						{
							this.$emit('update:processing', false)

							return true
						}
						else
						{
							this.failureToast();
						}
					}
					else
					{
						this.form_message = "formulaire.erreur_champs_non_remplis"
						this.failureToast('formulaire.erreur_champs_non_remplis')
					}

					this.$emit('update:processing', false)
					return false;
				}
			}

		},
		computed: {
			conditionsFormatted(){
				let tab = []

				for (let i = 0; i < this.conditions.length; i++) {
					tab.push({
						id: this.conditions[i].contractconditions_id,
						label: this.getTrad(this.conditions[i].contractconditions_label),
					})
				}

				return tab
			}
		},
		watch:{
			'default_form.author'(value) {
				if(value.id != undefined)
				{
					this.entity_id = value.id
					this.loadAllAfterTiers()
				}
			},
			'default_form.articles'(value) {
				if(value != undefined)
				{
					this.articles_id = value
					let article_selected = this.options_articles.filter(article => { return article.id == this.articles_id.id })[0]
					if(article_selected != undefined)
					{
						this.default_form.vat = article_selected.vat

						//Si c'est pas une pension je mets à jour le nom de l'article ainsi que son prix, car sinon ca pose problème avec le label et le prix
						this.default_form.htunit = article_selected.price
						this.default_form.label = article_selected.name
					}
				}
			},
			'articles.articles_label'() {
				this.articles.articles_code = this.accentsTidy(this.articles.articles_label).replace(/ /g, "_")
			},
			'articles.articles_accountingaccount'(val){
			    if(this.articles.articles_accountingaccountfdvxp == null || Object.keys(this.articles.articles_accountingaccountfdvxp).length == 0)
			    {
			        this.articles.articles_accountingaccountfdvxp = val
			    }

			    if(this.articles.articles_accountingaccountfdvex == null || Object.keys(this.articles.articles_accountingaccountfdvex).length == 0)
			    {
			        this.articles.articles_accountingaccountfdvex = val
			    }
			},
			'articles.articles_vataccount'(val){
			    if(this.articles.articles_vataccountfdvxp == null || Object.keys(this.articles.articles_vataccountfdvxp).length == 0)
			    {
			        this.articles.articles_vataccountfdvxp = val
			    }

			    if(this.articles.articles_vataccountfdvex == null || Object.keys(this.articles.articles_vataccountfdvex).length == 0)
			    {
			        this.articles.articles_vataccountfdvex = val
			    }
			}
		},
		components: {
			CustomTable: () => import('GroomyRoot/components/Table/CustomTable'),
			LoadingSpinner: () => import('GroomyRoot/components/Logos/LoadingSpinner_35'),
			ErrorAlert: () => import('GroomyRoot/components/Alert/ErrorAlert'),
		}
	}
</script>